"use client";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { ShowOverlayTooltip } from "@shared/ui/showOverlayTooltip";
import clsx from "clsx";

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  closedTitle?: string;
  tooltip: {
    title: string;
    content: React.ReactNode;
  };
}

export const Accordion: React.FC<AccordionProps> = ({ id, title, closedTitle, children, tooltip, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id={id} className="">
      <div className={clsx("flex items-center justify-center w-full gap-2", className)}>
        <button
          className="flex items-center justify-end gap-2 pl-4 pr-0 py-4 my-4 text-lg font-medium text-left focus:outline-none"
          aria-label="Toggle Accordion"
          onClick={toggleAccordion}
        >
          {isOpen ? (
            <ChevronUpIcon className="h-6 w-6 text-primary dark:text-dark-text" strokeWidth={2.5} />
          ) : (
            <ChevronDownIcon className="h-6 w-6 text-primary dark:text-dark-text" strokeWidth={2.5} />
          )}
          <span className="text-center uppercase text-sm text-primary font-semibold dark:text-dark-text">
            {isOpen ? title : closedTitle}
          </span>
        </button>
        <ShowOverlayTooltip overlayTitle={tooltip.title} content={tooltip.content} className="p-4" />
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};
