import dynamic from 'next/dynamic';

interface NoSsrWrapperProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Wrapper: React.FC<NoSsrWrapperProps> = ({ children, className, style }) => {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

export const NoSsrWrapper = dynamic(() => Promise.resolve(Wrapper), {
  ssr: false
});
