import clsx from "clsx";

type ToggleButtonGroupProps = {
  options: NonEmptyArray<{
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  }>;
  orButton?: boolean;
  className?: string;
  buttonClassName?: string;
  selectedIndex?: number;
};

export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  options,
  orButton,
  className,
  buttonClassName,
  selectedIndex,
}) => {
  return (
    <div className={clsx("flex justify-center relative", className)}>
      {orButton && (
        <div className="p-2 bg-white w-10 border border-solid shadow text-sm font-semibold rounded-full absolute mt-2 z-10">
          OR
        </div>
      )}

      {options.map((option, index) => {
        const isSelected = index === selectedIndex;

        return (
          <button
            data-testid={`toggle-button-${index}`}
            key={index}
            className={clsx(
              "w-full px-4 py-2 font-bold focus:outline-none uppercase text-xs sm:text-sm",
              isSelected
                ? "bg-primary text-white dark:bg-dark-primary"
                : "bg-secondary text-black font-bold shadow-md hover:opacity-80 dark:bg-dark-secondary dark:text-dark-text",
              index === 0 ? "rounded-l-lg" : "",
              index === options.length - 1 ? "rounded-r-lg" : "",
              buttonClassName
            )}
            onClick={(e) => option.onClick(e)}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};
