"use client";

import { forwardRef } from "react";

type NumericInputProps = React.ComponentProps<"input">;

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(function NumericInputInner(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className="w-[55px] py-1.5 px-3 text-center focus:outline-none focus:ring-transparent shadow-md border-none font-semibold min-w [&::-webkit-inner-spin-button]:appearance-none rounded-xl dark:bg-dark-primary dark:text-white"
      id="numericInput"
      type="number"
      pattern="[0-9]*"
      inputMode="numeric"
    />
  );
});
