import { useState } from "react";
import { DfsProIcon } from "@shared/icons";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { DescriptionModal } from "../DescriptionModal/DescriptionModal";

interface ShowOverlayTooltipProps {
  content?: React.ReactNode;
  overlayTitle: string;
  icon?: React.ReactNode;
  className?: string;
}

export const ShowOverlayTooltip: React.FC<ShowOverlayTooltipProps> = ({ overlayTitle, icon, content, className }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onClick = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <button onClick={onClick} title={overlayTitle} className={className}>
        {icon || <QuestionMarkCircleIcon height={18} width={18} className="h-full text-black dark:text-dark-text" />}
      </button>

      <DescriptionModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        className="rounded-t-xl py-4 px-6 dark:bg-dark-background"
      >
        <div className="flex items-center gap-x-3 dark:text-dark-text">
          <DfsProIcon className="w-10 dark:fill-dark-primary" />
          <h2 className="uppercase">{overlayTitle}</h2>
        </div>
        <div className="mt-4 text-sm md:text-base dark:text-dark-text">{content}</div>
      </DescriptionModal>
    </>
  );
};
