"use client";
import { useState, ReactNode, useEffect } from "react";
import clsx from "clsx";

type Option = {
  value: string | number;
  selected?: boolean;
  label: ReactNode;
  onClick?: () => void;
};

type SelectProps = {
  options: Option[];
  defaultValue?: string | number;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  labelClassName?: string;
  size?: "sm" | "md" | "lg";
  className?: string;
  dataTestid?: string;
  value?: string | number;
};

export const Select: React.FC<SelectProps> = ({
  options,
  placeholder,
  defaultValue,
  disabled,
  label,
  labelClassName,
  className,
  size = "md",
  dataTestid,
  value,
}) => {
  const defaultOption = options.find((option) => option.value === defaultValue);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(defaultOption);

  useEffect(() => {
    setSelectedOption(undefined);
  }, [disabled]);
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((option) => option.value === event.target.value);
    setSelectedOption(option);
    option?.onClick?.();
  };

  return (
    <div className={clsx("relative", className)}>
      {label && (
        <label className={clsx("uppercase xs:text-xs lg:text-sm dark:text-dark-text", labelClassName)}>{label}</label>
      )}
      <select
        className={clsx(
          "flex justify-between items-center uppercase w-full px-2 text-sm shadow-md bg-white border border-gray-300 rounded-md focus:outline-none disabled:opacity-30 disabled:cursor-not-allowed dark:bg-dark-secondary dark:border-0 dark:text-white",
          {
            "py-3": size === "lg",
            "py-2": size === "md",
            "py-1": size === "sm",
          },
          className
        )}
        disabled={disabled}
        value={value ?? selectedOption?.value}
        onChange={handleSelect}
        data-testid={dataTestid}
      >
        <option value="" hidden>
          {placeholder ?? "-"}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value} data-testid={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
