import clsx from "clsx";
import {
  CustomFlowbiteTheme,
  RangeSlider as FlowbiteRangeSlider,
  RangeSliderProps as FlowbiteRangeSliderProps,
} from "flowbite-react";

type SliderProps = FlowbiteRangeSliderProps & {
  variant?: "primary" | "bi-color";
};

const customTheme: CustomFlowbiteTheme["rangeSlider"] = {
  field: {
    input: {
      base: "w-full",
      sizes: {
        lg: "h-5 py-2",
      },
    },
  },
};

export const Slider: React.FC<SliderProps> = ({ value, className, ...props }) => {
  return (
    <FlowbiteRangeSlider
      {...props}
      value={value}
      theme={customTheme}
      className={clsx("accent-primary dark:accent-dark-primary", className)}
      sizing="lg"
    />
  );
};
