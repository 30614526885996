import clsx from "clsx";
import { forwardRef } from "react";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  addSearchIcon?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Inner(props, ref) {
  return (
    <div
      className={clsx(
        "flex gap-x-2 w-auto px-4 py-1 bg-white border-black rounded-full shadow-md dark:bg-dark-secondary dark:border-0",
        props.className
      )}
    >
      <input
        className="outline-none w-full border-none focus:ring-0 dark:bg-dark-secondary dark:text-white dark:placeholder:text-dark-text"
        type={props.type}
        ref={ref}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  );
});
