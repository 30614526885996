import { Slider } from "@nextui-org/slider";

type RangeSliderProps = {
  min: number;
  max: number;
  onChange: (value: number | number[]) => void;
};

export const RangeSlider: React.FC<RangeSliderProps> = ({ min, max, onChange }) => {
  const calculateStep = (max: number) => {
    if (max <= 10) return 0.1;
    if (max <= 100) return 1;
    if (max <= 1000) return 10;
    return 100;
  };

  return (
    <Slider
      showTooltip
      step={calculateStep(max)}
      size="sm"
      onChange={onChange}
      minValue={min}
      maxValue={max}
      defaultValue={[min, max]}
      className="max-w-md ml-2 mr-4"
      classNames={{
        mark: "pt-2 text-xs text-gray-500 dark:text-dark-text",
        thumb: "bg-primary dark:bg-dark-primary",
        filler: "bg-primary dark:bg-dark-primary",
        base: "bg-gray-500 rounded-lg",
      }}
      tooltipProps={{
        classNames: {
          content: ["text-white dark:text-dark-text"],
          base: ["bg-primary dark:bg-dark-primary"],
        },
      }}
      marks={[
        {
          value: min,
          label: String(min.toFixed(2)),
        },
        {
          value: max,
          label: String(max.toFixed(2)),
        },
      ]}
    />
  );
};
