import { Dropdown as FlowbiteDropdown, DropdownItem } from "flowbite-react";
import React from "react";

type DropdownProps = {
  label: string;
  items: {
    label: string;
    onChange: () => void;
  }[];
  className?: string;
};

export const Dropdown: React.FC<DropdownProps> = ({ label, items, className }) => {
  return (
    <FlowbiteDropdown label={label} inline className={className}>
      {items.map((item, index) => (
        <DropdownItem key={index} onClick={item.onChange}>
          {item.label}
        </DropdownItem>
      ))}
    </FlowbiteDropdown>
  );
};
