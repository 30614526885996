import { CustomFlowbiteTheme, ToggleSwitch, ToggleSwitchProps } from "flowbite-react";
import React from "react";

const customTheme: CustomFlowbiteTheme["toggleSwitch"] = {
  root: {
    base: "group relative flex items-center rounded-lg focus:outline-none",
    active: {
      on: "cursor-pointer",
      off: "cursor-not-allowed opacity-50",
    },
    label: "ml-3 text-xs font-medium text-white",
  },
  toggle: {
    base: "toggle-bg rounded-full border",
    checked: {
      on: "after:translate-x-full after:border-white",
      off: "border-gray-200 bg-gray-850 dark:border-gray-600 dark:bg-gray-750",
    },
  },
};

export const Toggle: React.FC<ToggleSwitchProps> = ({ checked, label, onChange }) => {
  return (
    <ToggleSwitch
      theme={customTheme}
      checked={checked}
      label={label}
      onChange={onChange}
      className="items-start text-white"
    />
  );
};
