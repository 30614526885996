import { CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

interface CardButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  Icon?: React.FC;
  selected?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  activated?: boolean;
}

export const ButtonCard: React.FC<CardButtonProps> = ({
  onClick,
  children,
  Icon,
  className,
  selected,
  disabled,
  activated,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        className,
        "relative flex w-full h-[72px] items-center justify-center gap-x-3 rounded-lg border-[1px] p-3 uppercase font-semibold shadow text-xs sm:text-sm",
        disabled
          ? "bg-secondary text-black shadow-md opacity-50 cursor-not-allowed hover:opacity-50 dark:bg-dark-secondary dark:text-dark-text dark:border-0"
          : selected
            ? "bg-primary text-white dark:bg-dark-primary dark:border-0"
            : "bg-secondary text-black shadow-md hover:opacity-80 dark:bg-dark-secondary dark:text-dark-text dark:border-0"
      )}
      disabled={disabled}
      {...props}
    >
      {activated && (
        <div className="bg-primary w-5 h-5 rounded-xl absolute top-[-7px] right-[-7px]">
          <CheckIcon color="white" />
        </div>
      )}
      {Icon && <Icon />}
      {children}
    </button>
  );
};
