import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { createPortal } from "react-dom";

interface DescriptionModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
}

export const DescriptionModal = ({ isOpen, onClose, className, children }: DescriptionModalProps) => {
  const handleCloseModal = (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (onClose) {
      onClose();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div
      data-testid="description-modal"
      onKeyDown={handleKeyDown}
      className={`fixed inset-0 flex items-center justify-center shadow-md bg-[#00000080] z-[9999997]`}
      onClick={handleCloseModal}
    >
      <div
        className={clsx(
          "relative m-auto mb-0 w-full max-w-3xl rounded-t-0 lg:mb-auto lg:rounded-lg bg-white",
          className
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row">
          <button
            id="close-DescriptionModal"
            data-testid="close-button"
            onClick={handleCloseModal}
            className="absolute right-2 xs:top-[16px] sm:top-[14px]"
          >
            <XMarkIcon className="xs:h-6 sm:h-8 text-black dark:text-dark-text" />
          </button>
        </div>

        {children}
      </div>
    </div>,
    document.getElementById("description") as Element
  );
};
