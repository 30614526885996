import clsx from "clsx";
import { SearchIcon } from "../../icons/Search/Search";

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  searchIcon?: boolean;
  placeholder?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ className, placeholder, searchIcon = true, ...props }) => {
  return (
    <div className={clsx("relative", className)}>
      <input
        type="text"
        className={clsx(
          "font-light w-full pr-4 py-1 text-[16px] placeholder:text-xs placeholder:uppercase border-2 border-gray-200 rounded-md focus:outline-none focus:border-primary dark:bg-dark-secondary dark:border-dark-secondary dark:text-dark-text dark:placeholder:text-dark-text",
          searchIcon && "pl-10"
        )}
        placeholder={placeholder}
        {...props}
      />
      {searchIcon && (
        <div className="absolute top-2 left-3">
          <SearchIcon className="w-5 h-5 dark:text-white" />
        </div>
      )}
    </div>
  );
};
