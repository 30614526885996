import clsx from "clsx";

type SideMenuButtonProps = {
  label: React.ReactNode;
  icon: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const SideMenuButton: React.FC<SideMenuButtonProps> = ({ icon, label, onClick, className, ...props }) => {
  return (
    <button
      className={clsx("items-center !flex gap-x-[10px] px-4 py-3 font-normal w-full", className)}
      onClick={onClick}
      {...props}
    >
      {icon}
      <span className={clsx("text-xs sm:text-sm truncate")}>{label}</span>
    </button>
  );
};
