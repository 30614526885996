import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip as FlowbiteTooltip, TooltipProps as FlowbiteTooltipProps } from "flowbite-react";

type TooltipProps = FlowbiteTooltipProps;

export const Tooltip: React.FC<TooltipProps> = (props) => {
  return (
    <FlowbiteTooltip {...props}>
      <QuestionMarkCircleIcon color="black" height={18} width={18} />
    </FlowbiteTooltip>
  );
};
