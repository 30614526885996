import clsx from "clsx";
import { ChangeEvent, InputHTMLAttributes, useCallback, useMemo } from "react";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = ({ id, name, value, onChange, className, children, checked }: CheckboxProps) => {
  const inputId = useMemo(() => id || `checkbox-${name}`, [id, name]);

  const handleLabelClick = useCallback(() => {
    onChange?.({ target: { name, checked: !checked } } as ChangeEvent<HTMLInputElement>);
  }, [name, onChange]);

  return (
    <div className="flex py-0.5 items-center gap-1.5">
      <input
        id={inputId}
        className={clsx(
          "peer relative shrink-0 appearance-none w-[18px] h-[18px] rounded bg-[#E8E8E8] checked:bg-[#0088D4] cursor-pointer dark:bg-dark-secondary dark:checked:bg-dark-primary",
          className
        )}
        type="checkbox"
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        aria-label={value?.toString()}
        aria-labelledby={inputId}
      />
      <label
        htmlFor={inputId}
        onClick={handleLabelClick}
        className="text-[16px] leading-[180%] cursor-pointer dark:text-dark-text"
      >
        {children || value}
      </label>
      <svg
        className="absolute w-[18px] h-[18px] hidden peer-checked:block pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
      >
        <path
          d="M6.28033 9.77501C5.98744 9.48211 5.51256 9.48211 5.21967 9.77501C4.92678 10.0679 4.92678 10.5428 5.21967 10.8357L7.94202 13.558C8.23492 13.8509 8.70979 13.8509 9.00268 13.558L15.447 7.1137C15.7399 6.82081 15.7399 6.34594 15.447 6.05304C15.1541 5.76015 14.6792 5.76015 14.3863 6.05304L8.47235 11.967L6.28033 9.77501Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
