import clsx from "clsx";
import React from "react";

type ChipProps = {
  label: string;
  className?: string;
};

export const Chip: React.FC<ChipProps> = ({ label, className }) => {
  return (
    <div
      className={clsx(
        `inline-flex items-center xs: px-1 sm:px-1.5 py-0.5 rounded-full xs:text-[7px] sm:text-xs font-medium bg-primary text-white`,
        className
      )}
    >
      {label}
    </div>
  );
};
