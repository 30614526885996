import {
  Tabs as FlowbiteTabs,
  TabsProps as FlowbiteTabsProps,
  TabItemProps as FlowbiteTabItemProps,
  CustomFlowbiteTheme,
} from "flowbite-react";

type TabsProps = FlowbiteTabsProps & {
  items?: Array<{
    index: number;
    color: string;
  }>;
};

export const Tabs: React.FC<TabsProps> = (props) => {
  const tabsTheme: CustomFlowbiteTheme["tabs"] = {
    base: "flex justify-center flex-col gap-2",
    tabpanel: "p-0",
    tablist: {
      base: "flex justify-around !flex-nowrap rounded-lg bg-[#AEAEAE] dark:bg-dark-secondary mx-4",
      tabitem: {
        base: "w-full py-1.5 !m-0 rounded-none first:rounded-l-lg last:rounded-r-lg !text-white text-sm uppercase first:border-none border-l-[1px] border-black",
        styles: {
          default: {
            base: "",
            active: {
              on: `bg-success hover:bg-success !text-white !dark:bg-dark-primary !dark:hover:bg-dark-primary ${props.items?.map(
                (item) => `[&:nth-child(${item.index + 1})]:!bg-[${item.color}]`
              )}`,
              off: "hover:opacity-40",
            },
          },
        },
      },
    },
  };

  return (
    <FlowbiteTabs {...props} theme={tabsTheme} style="default">
      {props.children}
    </FlowbiteTabs>
  );
};

type TabItemProps = FlowbiteTabItemProps;

export const TabItem: React.FC<TabItemProps> = (props) => {
  return <FlowbiteTabs.Item {...props}>{props.children}</FlowbiteTabs.Item>;
};
