import clsx from "clsx";

interface ToggleButtonProps {
  isOn: boolean;
  onToggle: () => void;
  className?: string;
  variant?: "lg" | "md" | "sm";
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ isOn, variant = "sm", onToggle, className }) => {
  const getButtonStyles = () => {
    switch (variant) {
      case "lg":
        return clsx("flex items-center rounded-[20px] bg-black", className, "min-h-[31px] min-w-[50px]");
      case "md":
        return clsx("flex items-center rounded-[9px] bg-black", className, "min-h-[18px] min-w-[30px]");
      case "sm":
        return clsx("flex items-center rounded-[7.5px] bg-black", className, "min-h-[15px] min-w-7");
    }
  };

  const getToggleStyles = () => {
    switch (variant) {
      case "lg":
        return clsx(
          "rounded-full bg-white transition-transform duration-300 ml-[3px]",
          isOn ? "translate-x-[19px] transform" : "translate-x-0 transform",
          "w-[25px] h-[25px]"
        );
      case "md":
        return clsx(
          "rounded-full bg-white transition-transform duration-300 ml-[3px]",
          isOn ? "translate-x-[13px] transform" : "translate-x-0 transform",
          "w-[13px] h-[13px]"
        );
      case "sm":
        return clsx(
          "rounded-full bg-white transition-transform duration-300",
          isOn ? "translate-x-[8px] transform" : "translate-x-0 transform",
          "w-[11px] h-[11px]"
        );
    }
  };

  return (
    <button
      className={clsx(
        getButtonStyles(),
        isOn ? "bg-primary dark:bg-dark-primary" : "bg-gray-300 dark:bg-dark-secondary",
        className
      )}
      onClick={onToggle}
    >
      <div className={getToggleStyles()} />
    </button>
  );
};
