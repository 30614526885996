type ActionButtonProps = {
  id?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
};

export const ActionButton: React.FC<ActionButtonProps> = ({ id, children, onClick, disabled, className }) => {
  return (
    <button
      id={id}
      className={`flex items-center gap-2 text-black shadow-md uppercase ${className} hover:bg-gray-850 xs:text-xs sm:text-sm`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
