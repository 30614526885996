import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import EmptyState from "../EmptyState/EmptyState";

type TableProps = {
  headers: {
    label: string;
    order?: "ASC" | "DESC";
    filter?: React.ReactNode;
    onSort?: () => void;
  }[];
  contents: Record<string, any>[];
};

export const Table: React.FC<TableProps> = ({ headers, contents }) => {
  return (
    <>
      <table className="w-full divide-y divide-gray-200 dark:divide-gray-550">
        <thead className="bg-background dark:bg-dark-nav dark:bg-opacity-0">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="pb-2 pr-4 text-left text-sm font-medium uppercase tracking-wider ">
                <div className="flex flex-col justify-between h-[60px] mb-2">
                  <div className="text-black">
                    <button
                      onClick={header.onSort}
                      className="flex justify-between items-center w-full hover:opacity-80"
                    >
                      <p className="uppercase text-sm dark:text-dark-text">{header.label}</p>
                      {header.order === "ASC" ? (
                        <ChevronDownIcon className="text-black dark:text-dark-text w-5" />
                      ) : header.order === "DESC" ? (
                        <ChevronUpIcon className="text-black dark:text-dark-text w-5" />
                      ) : null}
                    </button>
                  </div>
                  {header.filter}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        {contents.length > 0 && (
          <tbody className="bg-white divide-y divide-gray-200 dark:bg-dark-background dark:divide-gray-550">
            {contents.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                {Object.keys(row).map((key, keyIndex) => (
                  <td
                    key={keyIndex}
                    className={clsx(
                      "py-4 whitespace-nowrap text-sm text-gray-500 dark:text-dark-text",
                      keyIndex === 0 && "pl-4"
                    )}
                  >
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {contents.length === 0 && <EmptyState content={`No records available with the chosen settings.`} />}
    </>
  );
};
