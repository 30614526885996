import clsx from "clsx";

type LoadingCircleProps = {
  size?: "small" | "medium" | "large";
  color?: "black" | "white";
};

export const LoadingCircle: React.FC<LoadingCircleProps> = ({ size = "small", color = "black" }) => {
  const circleSizeClass = {
    small: "h-4 w-4",
    medium: "h-8 w-8",
    large: "h-14 w-14",
  }[size];

  return (
    <div
      aria-hidden="true"
      className={clsx(
        circleSizeClass,
        color === "white"
          ? "border-white dark:border-dark-primary dark:border-r-transparent"
          : "border-black dark:border-dark-primary dark:border-r-transparent",
        "inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      )}
      role="status"
    ></div>
  );
};
