import { Button, Modal } from "@shared/ui";

type ConfirmModalProps = {
  title: string;
  description: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;

  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} hasCloseBtn title={title}>
      <section className="px-7 pt-3 pb-5">
        <p className="mb-4 dark:text-dark-text">{description}</p>
        <div className="w-full flex justify-between gap-3">
          <Button variant="white" className="w-full" onClick={onCancel}>
            No
          </Button>
          <Button className="w-full" onClick={onConfirm}>
            Yes
          </Button>
        </div>
      </section>
    </Modal>
  );
};
