import Image from "next/image";
import noData from "@shared/assets/images/no-data.png";

interface EmptyStateProps {
  content: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ content }) => {
  return (
    <div className="flex flex-col items-center justify-center py-3">
      <Image src={noData} width={150} alt="man holding magnifying glass" />
      <p className="text-center xs:text-xs md:text-sm dark:text-dark-text">{content}</p>
    </div>
  );
};

export default EmptyState;
