"use client";

import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  closeBtnColor?: string;
  closeOnClickOutside?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  title?: string;
  titleIcon?: React.ReactNode;
  className?: string;
  tooltip?: React.ReactNode;
}

export const Modal = ({
  isOpen,
  hasCloseBtn = true,
  closeBtnColor = "text-white",
  onClose,
  title,
  titleIcon,
  className,
  children,
  tooltip,
  closeOnClickOutside = true,
}: ModalProps) => {
  const handleCloseModal = (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (onClose) {
      onClose();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div
      onKeyDown={handleKeyDown}
      className={`fixed inset-0 flex items-center justify-center shadow-md bg-[#00000080] z-[9999997]`}
      onClick={() => (closeOnClickOutside ? handleCloseModal() : () => {})}
    >
      <div
        className={clsx(
          "relative m-auto mb-0 w-full max-w-3xl rounded-t-0 lg:mb-auto lg:rounded-lg bg-white modal-open dark:bg-dark-background",
          className
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row">
          {title && (
            <div className="flex-1 flex justify-center gap-2 bg-primary py-4 px-2 dark:bg-dark-nav">
              <h2 className="flex justify-center items-center gap-x-2 font-bold text-white uppercase xs:text-[14px] sm:text-lg ml-4">
                {titleIcon}
                {title}
                {tooltip}
              </h2>
            </div>
          )}
          {hasCloseBtn && (
            <button
              id="close-modal"
              onClick={handleCloseModal}
              className="absolute right-2 xs:top-[16px] sm:top-[14px]"
            >
              <XMarkIcon className={clsx("xs:h-6 sm:h-8", closeBtnColor)} />
            </button>
          )}
        </div>

        {children}
      </div>
    </div>,
    document.getElementById("modal") as Element
  );
};
