export const LoadingDots = () => {
  return (
    <div className="flex items-center justify-center gap-1 h-full w-full">
      <span className="bg-gray-850 dark:bg-gray-200 inline-block h-[7px] w-[7px] rounded-full animate-[loading-dot_1.2s_ease-in-out_infinite]"></span>
      <span
        className="bg-gray-850 dark:bg-gray-200 inline-block h-[7px] w-[7px] rounded-full animate-[loading-dot_1.2s_ease-in-out_infinite]"
        style={{ animationDelay: "0.3s" }}
      ></span>
      <span
        className="bg-gray-850 dark:bg-gray-200 inline-block h-[7px] w-[7px] rounded-full animate-[loading-dot_1.2s_ease-in-out_infinite]"
        style={{ animationDelay: "0.4s" }}
      ></span>
    </div>
  );
};
