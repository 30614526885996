import clsx from "clsx";
import React from "react";

type LabelProps = {
  children: React.ReactNode;
  className?: string;
};

export const Label: React.FC<LabelProps> = ({ children, className }) => {
  return <div className={clsx("shadow-md px-1 ", className)}>{children}</div>;
};
