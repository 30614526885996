import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";

type Button = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "tertiary"
    | "black"
    | "white"
    | "outline"
    | "outline-black"
    | "outline-white";
  size?: "sm" | "md" | "lg";
};

export const Button: React.FC<Button> = ({
  id,
  children,
  onClick,
  className,
  variant = "primary",
  size = "md",
  ...props
}) => {
  return (
    <button
      id={id}
      className={clsx(
        `flex items-center justify-center gap-2 rounded-[5px] text-black shadow-md uppercase border disabled:pointer-events-none disabled:opacity-40 hover:opacity-80`,
        className,
        {
          "bg-primary text-white dark:bg-dark-primary dark:border-0": variant === "primary",
          "bg-secondary text-black dark:bg-dark-secondary dark:border-0": variant === "secondary",
          "bg-success text-white dark:bg-dark-success dark:border-0": variant === "success",
          "bg-tertiary text-white": variant === "tertiary",
          "bg-black text-white hover:bg-gray-600 dark:bg-dark-primary dark:border-0": variant === "black",
          "bg-white text-black border-black border-0 dark:bg-dark-secondary dark:border-0 dark:text-dark-text":
            variant === "white",
          "text-primary border-primary border-2 dark:text-dark-primary": variant === "outline",
          "bg-white text-black border-black border-2 dark:border-dark-primary dark:bg-transparent dark:text-dark-text":
            variant === "outline-black",
          "bg-black text-white border-white border-2": variant === "outline-white",
        },
        {
          "px-4 py-2 text-xs": size === "sm",
          "px-8 py-3 text-base": size === "md",
          "px-8 py-4 text-lg": size === "lg",
        }
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};
