"use client";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const page = parseInt(event.target.value, 10);
    if (!isNaN(page) && page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const pageOptions = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="flex items-center justify-center space-x-4">
      <button
        className="h-[40px] w-[40px] flex items-center justify-center p-2 text-sm font-medium text-white bg-black dark:bg-dark-primary border border-gray-300 dark:border-0 rounded-3xl hover:bg-gray-750 dark:hover:bg-dark-primary dark:hover:bg-opacity-40 disabled:bg-gray-300 dark:disabled:bg-gray-600 disabled:cursor-not-allowed"
        onClick={handlePrevPage}
        aria-label="prev"
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon className="h-4" />
      </button>
      <select
        className="px-2 py-1 text-sm text-center text-gray-500 bg-white dark:bg-dark-primary dark:text-dark-text border border-gray-300 dark:border-gray-550 rounded-md focus:outline-none focus:ring focus:ring-gray-300"
        value={currentPage}
        onChange={handlePageInputChange}
      >
        {pageOptions.map((page) => (
          <option key={page} value={page}>
            {page}
          </option>
        ))}
      </select>
      <button
        className="h-[40px] w-[40px] flex items-center justify-center p-2 text-sm font-medium  text-white bg-black dark:bg-dark-primary border border-gray-300 dark:border-0 rounded-3xl hover:bg-gray-750 dark:hover:bg-dark-primary dark:hover:bg-opacity-40 disabled:bg-gray-300 dark:disabled:bg-gray-550 disabled:cursor-not-allowed"
        onClick={handleNextPage}
        aria-label="next"
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon className="h-4" />
      </button>
    </div>
  );
};
